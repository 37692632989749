// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App{
  font-family: "Poppins";
}
.text-center{
  text-align: center;
  color: rgb(11, 11, 92);
}
.white{
  color: white;
}
.main-content{
  display: flex;
  flex-direction:column;
}
.heading-text{
  width: 90%;
  color: #676767;
  margin-bottom: 20px;
}
.body-section {
  display: flex;
  margin-top: 78px;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.7; }
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

/* .App{
  display: flex;
  flex-wrap: wrap; 
  justify-content: right;
} */

 `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,KAAK,UAAU,EAAE;EACjB,OAAO,YAAY,EAAE;AACvB;;AAEA;EACE,IAAI,UAAU,CAAC;EACf,MAAM,UAAU,CAAC;AACnB;;AAEA;;;;GAIG","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap\");\n.App{\n  font-family: \"Poppins\";\n}\n.text-center{\n  text-align: center;\n  color: rgb(11, 11, 92);\n}\n.white{\n  color: white;\n}\n.main-content{\n  display: flex;\n  flex-direction:column;\n}\n.heading-text{\n  width: 90%;\n  color: #676767;\n  margin-bottom: 20px;\n}\n.body-section {\n  display: flex;\n  margin-top: 78px;\n}\n\n@keyframes fadeIn {\n  0% { opacity: 0; }\n  100% { opacity: 0.7; }\n}\n\n@keyframes fadeOut {\n  0% {opacity: 1;}\n  100% {opacity: 0;}\n}\n\n/* .App{\n  display: flex;\n  flex-wrap: wrap; \n  justify-content: right;\n} */\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
