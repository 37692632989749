import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Sidebar from "./components/sidebar/sidebar";
import Header from './components/header/Header';
import Home from './pages/home';
import AddDetails from './pages/add_person';
import DescriptionPage from './pages/description';
import AdminEditFiles from './pages/admin_edit_files';
import EditFileComponent from './pages/admin_edit_file_id';
import Login from './pages/login';
import { corporateUrl } from './config/config';

function App() { 
  const [isAdmin, setIsAdmin] = useState(true);
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication
  );
  const [activeIndex,setActiveIndex] = useState(1);
  return (
    <Router> 
      <div className="App"> 
        <div className="main-content">
          <div className="body-section">
            <div className="d-flex flex-column flex-xl-row w-100">
              <Header /> 
              {authenticated && <div className="sidebar-section min-vh-0">
                <Sidebar activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
              </div>}
              <Routes> 
                <Route path="/" element={<Home />} />
                <Route path="/home/:id" element={<Home />} />
                <Route path="/add_software/:id" element={<AddDetails />} />
                <Route path="/details" element={<DescriptionPage />} />
                <Route path="/all_software/:id" element={<AdminEditFiles />} />
                <Route path="/edit_software/:id" element={<EditFileComponent />} />
                <Route
                path="/login"
                element={<Login setAuthenticated={setAuthenticated} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div> 
    </Router>
  );
}

export default App;
