import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icon1 } from './../svgs/search-outline.svg';
import './../css/pages/admin_edit_files.css';
import no_data from "./../svgs/7466073.png";
import { Table, Image } from 'react-bootstrap';
import profile from "./../profile.png";
import BaseUrl from '../config/config';
import api from '../api/api';

const roles = [
    'All',
    'Product Team Developers',
    'Service Area Developers',
    'DB Team',
    'Testers',
    'Business Analysts',
    'Business Development',
    'HR'
];

const TabComponent = ({ selectedRole, setSelectedRole }) => {
    const handleRoleClick = (role) => {
        setSelectedRole(role);
    };

    return (
        <div className="admin_edit_files_tabs_outer_container">
        <div className="admin_edit_files_tab_container">
            {roles.map((role) => (
                <div
                    key={role}
                    className={`admin_edit_files_tab ${selectedRole === role ? 'admin_edit_files_active' : ''}`}
                    onClick={() => handleRoleClick(role)}
                >
                    <div className='admin_adit_files_tab_text'>
                    {role}
                    </div>
                </div>
            ))}
        </div>
        </div>
    );
};

function AdminEditFiles() {
    const token = localStorage.getItem('token');
    const isAdmin = localStorage.getItem('access_type');
    const [items, setItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRole, setSelectedRole] = useState('All');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(true);

    const itemsPerPage = 5;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await api.get(`${BaseUrl}/files`,{
                headers: {
                    'Authorization': `Bearer ${token}`,
                }}
                );
                setItems(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    //delete function
    const handleDelete = async (fileId) => {
        try {
            await api.delete(`${BaseUrl}/files/delete/${fileId}`,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }}
            );
            setItems(items.filter(item => item._id !== fileId));
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };
    
    //search function
    const searchHandler = async (query) => {
        setLoading(true);
        try {
            const response = await api.post(
                `${BaseUrl}/files/search`,
                { filename: query },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setItems(response.data);
            setCurrentPage(1);
            setLoading(false);
        } catch (error) {
            console.error('Error searching files:', error);
            setLoading(false);
        }
    };
    

    //debouncing function
    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearchHandler = useCallback(debounce(searchHandler, 1000), []);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        debouncedSearchHandler(query);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    //filtering search and all items based on date and role selected
    const filteredItems = items.filter(application => {
        const isRoleMatch = selectedRole === 'All' ||
            (application.roles && (application.roles.includes('all') || application.roles.includes(selectedRole)));
        const uploadedDate = new Date(application.uploadedDate);
        const endOfDay = endDate ? new Date(new Date(endDate).setHours(23, 59, 59, 999)) : null;
        const isDateMatch = (!startDate || uploadedDate >= new Date(startDate)) &&
            (!endDate || uploadedDate <= endOfDay);
        return isRoleMatch && isDateMatch;
    });
    
    
    
    //pagination spliting 
    const paginatedItems = filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    return (
        <div className='admin_edit_files_App_main'>
            <div className="admin_edit_files_App_inner">
                <div className='admin_edit_files_home_heading_section'>
                    <p className='admin_edit_files_home_heading_text'>All Softwares</p>
                    <p className='admin_edit_files_home_heading_text_p'>Edit or delete attached software and their respective details</p>
                </div>
                <TabComponent selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
            <div className='admmin_edit_files_filter_section'>
                <div className="admin_edit_file_search_section">
                    <div className="admin_edit_file_search_container">
                        <Icon1 className="admin_edit_file_search_icon_home" width={20} height={20} />
                        <input
                            type="text"
                            placeholder="Search by filename"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search_bar"
                        />
                    </div>
                </div>

                <div className="admin_edit_files_date_filter">
                    <label className='admin_edit_files_date_label'>
                        Start Date :
                    </label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="admin_edit_files_date_input"
                    />
                    <label className='admin_edit_files_date_label'>
                        End Date :
                    </label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="admin_edit_files_date_input"
                    />
                </div>
            </div>

            <div className='table_edit_files_container'>
            {loading ? (
                <div className="loading-circle">Loading...</div>
                ) : (
                <>
            {/*conditional rendering of table based on if data with filters are available*/}

                {paginatedItems.length > 0 ? (
            <Table hover style={{ flex: "0 0 85%", width: "100%"}}>
            <thead>
                <tr>
                    <th style={{ width: '4%' }}></th>
                    <th style={{ width: '', fontSize: '13px', fontWeight: 'bold', textAlign: 'left', color:'#000' }}>File Name</th>
                    <th style={{ width: '15%', fontSize: '13px', fontWeight: 'bold', textAlign: 'left', color:'#000' }}>Uploaded Date</th>
                    <th style={{ width: '15%', fontSize: '13px', fontWeight: 'bold', textAlign: 'left', color:'#000' }}>Updated Date</th>
                    <th style={{ width: '25%', fontSize: '13px', fontWeight: 'bold', textAlign: 'left', color:'#000' }}>Uploaded By</th>
                    {isAdmin && isAdmin === '1' && (
                        <>
                            <th style={{ width: '5%', fontSize: '13px', fontWeight: 'bold', textAlign: 'left' }}></th>
                            <th style={{ width: '5%', fontSize: '13px', fontWeight: 'bold', textAlign: 'left' }}></th>
                        </>
                    )}
                </tr>
            </thead>
            <tbody>
                {paginatedItems.map((application, index) => (
                <tr
                    key={index}
                    onClick={() => navigate('/description', { state: { application } })}
                    style={{ height: '6rem', padding: '100px' }}
                >
                    <td style={{verticalAlign: 'middle'}}><img src={application.image} className="admin_edit_files_image_icon_home" alt="Image" /></td>
                    <td style={{fontWeight: '500', verticalAlign: 'middle', color: 'rgb(63, 63, 63)'}}>{application.filename}</td>
                    <td style={{ color : 'gray',verticalAlign: 'middle'}}>{new Date(application.uploadedDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</td>
                    <td style={{ color : 'gray', verticalAlign: 'middle'}}>{new Date(application.updatedDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</td>
                    <td style={{ color : 'gray', verticalAlign: 'middle'}}>
                        <div className='uploaded_by_container'>
                            {application.profilePic == ''|| application.profilePic == null ? 
                                <img  src="/images/profile-user.png" className='uploaded_by_image'/> 
                            : <img  src={application.profilePic} className='uploaded_by_image'/>
                            }
                            <div className='uploaded_by_details'>
                                <label className='uploaded_by_name'>{application.AdminName}</label>
                                <label className='uploaded_by_email'>{application.Email}</label>
                            </div>
                        </div>
                    </td>

                    {isAdmin && isAdmin === '1' && (
                        <>
                            <td style={{ verticalAlign: 'middle' }}>
                            <div
                                className='admin_edit_files_edit_button'
                                onClick={(e) => {
                                e.stopPropagation();
                                console.log(application._id);
                                navigate(`/edit_software/${localStorage.getItem('user_id')}`,{state : {application}});
                                }}
                            >
                                Edit
                            </div>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                            <div
                                className='admin_edit_files_delete_button'
                                onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(application._id);
                                }}
                            >
                                Delete
                            </div>
                            </td>
                        </>
                    )}
                </tr>
                ))}
            </tbody>
            </Table>
        ) : (


            // in case of no found data
            <div className='no_data_home_main'>
            <div className='no_data_home_main_image_section'>
                <img src={no_data} className='no_data_home_main_image' alt="No Data" />
            </div>
            <p className='no_data_home_main_description'>No Files Found!</p>
            </div>
        )}
                </>
            )}
        </div>
        {
            !loading && (
                <div className='pagination'>
            {/* Back Arrow */}
            <p
                onClick={() => handlePageChange(currentPage - (currentPage!=1))}
                className={`pagination_arrow ${currentPage === 1 ? 'disabled' : ''}`}
            >
        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="nxt-prev-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12"></path></svg>
            </p>
        
            {/* Page Numbers */}
            {Array.from({ length: totalPages }, (_, i) => (
                <div
                    key={i}
                    onClick={() => handlePageChange(i + 1)}
                    className={`pagination_button ${i + 1 === currentPage ? 'active' : ''}`}
                >
                    {i + 1}
                </div>
            ))}
        
            {/* Front Arrow */}
            <p
                onClick={() => handlePageChange(currentPage + (currentPage != totalPages ))}
                className={`pagination_arrow ${currentPage === totalPages ? 'disabled' : ''}`}
            >
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="nxt-prev-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6"></path></svg>
            </p>
        </div>
            )
        }

        
            </div>
        </div>
    );
}

export default AdminEditFiles;
