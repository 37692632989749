import React from 'react';
import { useLocation } from 'react-router-dom';
import "./../css/components/description.css";
import { FaFileDownload } from "react-icons/fa";

function DescriptionPage() {
    const location = useLocation();
    const { application } = location.state || {};

    if (!application) {
        return <div>No application data available</div>;
    }

    return (
        <div className="description_page_main">
            <div className='details-content'>
                
                <div className='description_page_download_section'>
                    <a href = {application.link}  target='_blank' className="description_page_download">To Download <FaFileDownload /></a>
                </div>
                <div className='inner-content-sec'>
                    <div className='details-image-sec'>
                        <img src={application.image} className="description_page_logo" alt="Image" />
                    </div>
                    <div className="details_title">
                        <h2>{application.filename}</h2>
                    </div>
                    <div className="description_page_description">
                        {application.description}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DescriptionPage;
