import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams,useLocation } from 'react-router-dom';
import '../../css/components/sidebar/sidebar.css';
import { corporateUrl } from '../../config/config';
import baseUrl from '../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome,faFileCirclePlus,faComputer, faLaptopMedical,faChalkboardUser,faRightFromBracket, faHistory } from '@fortawesome/free-solid-svg-icons';
import { PiFilesFill } from "react-icons/pi";
import { Tooltip } from 'react-tooltip';
import api from '../../api/api';

const Sidebar = ({activeIndex, setActiveIndex}) => {
    // const navigate = useNavigate();
    const location = useLocation();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [userDetails, setDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [id, setId] = useState("");
    // const [activeIndex,setActiveIndex] = useState(1);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const fetchDetails = async () => {

        try {
            const token = localStorage.getItem('token'); //retrive the token from the local storage
            const response = await api.get(`${corporateUrl}/api/user-details/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            });
            setDetails(response.data);
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    };

    const navigate = (id) => {
        setActiveIndex(id);
    }

    useEffect(() => {
        setTimeout(() => {
            setId(localStorage.getItem('user_id'))
        }, 1000)

        fetchDetails();

    }, [id]);


    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        try {
            // Remove token and user_id from localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('authenticated')
            window.close()
            const response = await api.post(`${baseUrl}/api/logout`, { id: id });
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div className="sid_nav_main">
            {(
            <button className="expand_button" onClick={toggleExpand}>
                {!isExpanded ? `☰` : ` X`}
            </button>
            )}
            <div className={`sidebar visible ${isExpanded ? 'expanded' : 'collapsed'}`}>

                <div className="sidebar visible">
                    <div>
                        <Link to={`/home/${id}`} >
                            {/* <img src='/images/hom.png' alt="Home Icon" className="sidebar-icon" /> */}
                            <FontAwesomeIcon data-tooltip-id="tool-tip-nav1" data-tooltip-content="Home" className={location.pathname == `/home/${id}`? "font-icons active" : "font-icons"} icon={faHome} onClick={() => navigate(1)}/>
                            <Tooltip id="tool-tip-nav1" className="nav-tooltip" border="1px solid #5e37f6"/>
                        </Link>
                        
                        {userDetails && userDetails.access_type === 1 && (
                            <>
                                <Link to={`/add_software/${id}`} >
                                    <FontAwesomeIcon data-tooltip-id="tool-tip-nav2" data-tooltip-content="Add Software" className={location.pathname ==`/add_software/${id}` ? "font-icons active" : "font-icons"} icon={faFileCirclePlus} onClick={() => navigate(2)}/>
                                    <Tooltip id="tool-tip-nav2" className="nav-tooltip"border="1px solid #5e37f6" />
                                </Link>
                            </>
                        )}
                        {userDetails && (userDetails.access_type === 1 || userDetails.access_type === 3) && (
                            <>
                                <Link to={`/all_software/${id}`} >
                                    <PiFilesFill data-tooltip-id="tool-tip-nav3" data-tooltip-content="All Software" className={location.pathname ==`/all_software/${id}` ? "font-icons menu-icon-cutom active" : "font-icons menu-icon-cutom"} onClick={() => navigate(3)}/>
                                    <Tooltip id="tool-tip-nav3" className="nav-tooltip" border="1px solid #5e37f6"/>
                                </Link>
                            </>
                        )}
                            
                    </div>
                    <div className="logout">
                        <Link to="/login" onClick={handleLogout}>
                            <FontAwesomeIcon data-tooltip-id="tool-tip-nav-logout" data-tooltip-content="Logout" className='font-icons fa-light' icon={faRightFromBracket} />
                            <Tooltip id="tool-tip-nav-logout" className="nav-tooltip" border="1px solid #5e37f6"/>
                        </Link>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
};

export default Sidebar;
