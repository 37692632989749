import React, { useState, useEffect, useCallback, } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Icon } from './../svgs/folder-outline.svg';
import { ReactComponent as Icon1 } from './../svgs/search-outline.svg';
import './../css/pages/home.css';
import no_data from "./../svgs/7466073.png";
import { Table, Button, Image } from 'react-bootstrap';
import BaseUrl, { corporateUrl } from '../config/config';
import api from '../api/api';
import { FaFileDownload } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { IoInformationCircleSharp } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
//import Popup from './description';

const roles = ['All','Product Team Developers', 'Service Area Developers', 'DB Team','Testers','Business Analysts','Business Development','HR'];

//role selecting part
const TabComponent = ({ selectedRole, setSelectedRole}) => {
    const handleRoleClick = (role) => {
        setSelectedRole(role);
    };

    return (
        <div className="admin_edit_files_tabs_outer_container">
        <div className="admin_edit_files_tab_container">
            {roles.map((role) => (
                <div
                    key={role}
                    className={`admin_edit_files_tab ${selectedRole === role ? 'admin_edit_files_active' : ''}`}
                    onClick={() => handleRoleClick(role)}
                >
                    <div className='admin_adit_files_tab_text'>
                    {role}
                    </div>
                </div>
            ))}
        </div>
        </div>
    );
};


//description popup

const Popup = ({ application, onClose }) => {
    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <div className='close-button-popup'>
                    <IoMdCloseCircle onClick={onClose} />
                </div>
                    <div className='detail-head-sec'>
                        <div className='details-image-sec'>
                            <img src={application.image} className="detail_logo" alt="Image" />
                        </div>
                        <div className="details_title">
                            <h2>{application.filename}</h2>
                        </div>
                    </div>
                    <div>
                        <p>{application.description}</p>
                    </div>
                <div className='detail_download_section'>
                    <a href = {application.link}  target='_blank' className="detail_download">To Download <FaFileDownload /></a>
                </div>
            </div>
        </div>
    );
};


function Home() {
    const id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const [items, setItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);  
    const [selectedRole,setSelectedRole] = useState('All');
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 10;
    const [popupContent, setPopupContent] = useState(null);
    const [userDetails, setUserDetails] = useState([]);
    var today = new Date()
    var currentTime = today.getHours()

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await api.get(`${BaseUrl}/files`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }});
                setItems(response.data);
                setLoading(false);

                const userResponse = await api.get(`${corporateUrl}/api/user-details/${id}`, {
                    headers: {
                      'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });
                setUserDetails(userResponse.data);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    //search function
    const searchHandler = async (query) => {
        setLoading(true);
        try {
            const response = await api.post(
                `${BaseUrl}/files/search`,
                { filename: query },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setItems(response.data);
            setCurrentPage(1);
            setLoading(false);
        } catch (error) {
            console.error('Error searching files:', error);
            setLoading(false);
        }
    };
    
    //debouncing function
    const debounce = (func, delay) => {
        let timer;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearchHandler = useCallback(debounce(searchHandler, 1000), []);

    const handleIconHover = (application) => {
        setPopupContent(application);
    };

    const handleClosePopup = () => {
        setPopupContent(null);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        debouncedSearchHandler(query);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    //filtering based on roles selected
    const filteredItems = selectedRole === 'All' ? items : items.filter(application => {
        if (application.roles && application.roles.includes('all')) {
            return true; 
        }
        return application.roles && application.roles.includes(selectedRole);
    });
    

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedRole]);
    

    //pagination spliting logic
    const paginatedItems = filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    return (
        <div className="App_inner_main">
            <div className="App_inner">
                <div className='hello-head'>
                    <div className='hello-text'>
                        <span>Hello, {userDetails.first_name+ ' '+ userDetails.last_name} !</span>
                    </div>
                    <div className='wish-text'>
                        {currentTime<12 ? <h5>Good Morning</h5>: currentTime< 18 ? <h5>Good Afternoon</h5>: <h5>Good Evening</h5>}
                    </div>
                </div>
                
                <div className='home_heading_section'>
                    <p className='home_heading_text'>Software Downloads</p>
                    <p className='home_heading_text_p'>Locate the official website where you can download each of the following software programs, and a brief description of what each software does.</p>
                </div>

                <TabComponent selectedRole={selectedRole} setSelectedRole={setSelectedRole} />

                <div className="search_section">
                    <div className="search_container">
                        <Icon1 className="search_icon_home" width={20} height={20} />
                        <input
                            type="text"
                            placeholder="Search by filename"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search_bar"
                        />
                    </div>
                </div>

                <div className='table_view_files_container'>
                {loading ? (
                <div className="loading-circle">Loading...</div>
                
                ) : (
                <>
                {/* conditional rendering table based on if filtered paginated items are empty */}
                {paginatedItems.length > 0 ? (
                    <div className="software-downloads-lists-main">
                        {paginatedItems.map((application, index) => (
                            <div className='item-main'>
                                <div className="software-downloads-list-item ">
                                    <div className="software-downloads-list-item-first">
                                        <div className="software-downloads-item-image">
                                            <Image src={application.image} className="image_icon_home" alt="Image"/>
                                        </div>
                                    </div>
                                    <div className="software-downloads-list-item-first-second">
                                        <div className="software-downloads-item-text">
                                            <span>{application.filename}</span>
                                        </div>
                                        <div className='software-downloads-button'>
                                            <IoInformationCircleSharp onClick={() => handleIconHover(application)} />
                                            {/* <IoInformationCircleSharp data-tooltip-id="item-tool-tip-1" data-tooltip-content="Know More" onClick={() => navigate('/details', { state: { application } })}/> */}
                                            {/* <Tooltip id="item-tool-tip-1" className="item-tooltip" border="1px solid #5e37f6"/> */}
                                            
                                            <a target='_blank' href={application.link.startsWith('/api/addData/download/') ? `http://localhost:3001${application.link}` : application.link}>
                                                <FaDownload data-tooltip-id="item-tool-tip-2" data-tooltip-content="Download"/>
                                                <Tooltip id="item-tool-tip-2" className="item-tooltip" border="1px solid #5e37f6"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {popupContent && <Popup application={popupContent} onClose={handleClosePopup} />}
                    </div>
                    
                ) : (
                    //in case of no data found
                    <div className='no_data_home_main'>
                    <div className='no_data_home_main_image_section'>
                        <img src={no_data} className='no_data_home_main_image' alt="No Data" />
                    </div>
                    <p className='no_data_home_main_description'>No Files Found!</p>
                    </div>
                )}
                        </>
                    )}
                </div>
                    
                {
                    !loading && (
                        <div className='pagination'>
                        {/* Back Arrow */}
                        <p
                            onClick={() => handlePageChange(currentPage - (currentPage!=1))}
                            className={`pagination_arrow ${currentPage === 1 ? 'disabled' : ''}`}
                        >
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="nxt-prev-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12"></path></svg>
                        </p>
                    
                        {/* Page Numbers */}
                        {Array.from({ length: totalPages }, (_, i) => (
                            <div
                                key={i}
                                onClick={() => handlePageChange(i + 1)}
                                className={`pagination_button ${i + 1 === currentPage ? 'active' : ''}`}
                            >
                                {i + 1}
                            </div>
                        ))}
                    
                        {/* Front Arrow */}
                        <p
                            onClick={() => handlePageChange(currentPage + (currentPage != totalPages ))}
                            className={`pagination_arrow ${currentPage === totalPages ? 'disabled' : ''}`}
                        >
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="nxt-prev-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6"></path></svg>
                        </p>
                    </div>
                    

                    )
                    
                }  
            
            </div>
        </div>
    );
}

export default Home;
