// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupq { 
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10rem;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 1rem;
    flex-wrap: wrap;
    text-align: center;
    display: flex;
    justify-content:space-around
  }
  .outer-container{
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 20px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 1px solid #ccc;
    border-radius: 1rem;
    flex-wrap: wrap;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .popupq_text{
    flex: 0 0 100%;
    color: gray;
    font-size: 1rem;
  }

  .popupq_button1{
    flex: 0 0 30%;
    align-items: center;
    color: black;
    background-color: white;
    border-radius: 2rem;
   }

.popupq_button2{
    flex: 0 0 30%;
    align-items: center;
    color: white;
    background-color: black;
    border-radius: 2rem;
}


@media screen and (max-width: 800px) {
    .popupq {
        height: 15rem;
     }
    .popupq_button1{
        flex: 0 0 60%;
    }
    .popupq_button2{
        flex: 0 0 60%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/components/popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb;EACF;EACA;IACE,eAAe;IACf,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,WAAW;IACX,gCAAgC;IAChC,aAAa;IACb,mCAA2B;YAA3B,2BAA2B;IAC3B,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;GACpB;;AAEH;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI;QACI,aAAa;KAChB;IACD;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".popupq { \n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    height: 10rem;\n    padding: 20px;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-radius: 1rem;\n    flex-wrap: wrap;\n    text-align: center;\n    display: flex;\n    justify-content:space-around\n  }\n  .outer-container{\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    height: 100%;\n    width: 100%;\n    transform: translate(-50%, -50%);\n    padding: 20px;\n    backdrop-filter: blur(10px);\n    border: 1px solid #ccc;\n    border-radius: 1rem;\n    flex-wrap: wrap;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n  }\n\n  .popupq_text{\n    flex: 0 0 100%;\n    color: gray;\n    font-size: 1rem;\n  }\n\n  .popupq_button1{\n    flex: 0 0 30%;\n    align-items: center;\n    color: black;\n    background-color: white;\n    border-radius: 2rem;\n   }\n\n.popupq_button2{\n    flex: 0 0 30%;\n    align-items: center;\n    color: white;\n    background-color: black;\n    border-radius: 2rem;\n}\n\n\n@media screen and (max-width: 800px) {\n    .popupq {\n        height: 15rem;\n     }\n    .popupq_button1{\n        flex: 0 0 60%;\n    }\n    .popupq_button2{\n        flex: 0 0 60%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
