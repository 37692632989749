import React from 'react';
import './../css/components/popup.css'; // Import the CSS file for styling

const Popup = ({ message,setShowPopup,resolveFunction }) => {
    const handleButtonClick = (value) => {
        // Call the resolve function with the appropriate value when a button is clicked
        resolveFunction(value);
        // Close the popup
        setShowPopup(false);
    };

    return (
        <div className='outer-container'>
            <div className="popupq">
                <p className="popupq_text">{message}</p>
                <button className="popupq_button1" onClick={() => handleButtonClick(true)}>Ok</button>
            </div>
        </div>
    );
};

export default Popup;
