import React, { useState, useRef, useEffect } from 'react';
import { corporateUrl } from '../config/config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/pages/form.css';
import BaseUrl from '../config/config';
import api from '../api/api';
import Popup from '../components/popup';


const availableRoles = ['Product Team Developers', 'Service Area Developers', 'DB Team','Testers','Business Analysts','Business Development','HR'];

//selector section function
const RolesSelector = ({ selectedRoles, onRoleChange }) => {
    const isAllSelected = selectedRoles.length === availableRoles.length;
    const handleCheckboxChange = (role) => {
        if (role === 'All') {
            if (isAllSelected) {
                onRoleChange([]); // Deselect all
            } else {
                onRoleChange([...availableRoles]); // Select all
            }
        } else {
            if (selectedRoles.includes(role)) {
                onRoleChange(selectedRoles.filter((r) => r !== role));
            } else {
                onRoleChange([...selectedRoles, role]);
            }
        }
    };

    return (

        //role selection section
        <div className='add_file_role_section'>
            <label className='checkbox-container add_file_role_label'>
                <input
                    type="checkbox"
                    value="All"
                    checked={isAllSelected}
                    onChange={() => handleCheckboxChange('All')}
                    className='add_file_role_selector'
                />
                All
            </label>
            {availableRoles.map((role) => (
                <label key={role} className='checkbox-container add_file_role_label'>
                    <input
                        type="checkbox"
                        value={role}
                        checked={selectedRoles.includes(role)}
                        onChange={() => handleCheckboxChange(role)}
                        className='add_file_role_selector'
                    />
                    {role}
                </label>
            ))}
        </div>
    );
};



function AddDetails() {

    const [userDetails, setDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [showPopup,setShowPopup] = useState(false);
    const [id, setId] = useState("")

    const fetchDetails = async () => {      

        try {
            const token = localStorage.getItem('token'); //retrive the token from the local storage
            console.log("trying to get user details");
            console.log(token);
            const response = await api.get(`${corporateUrl}/api/user-details/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            });
            setDetails(response.data);
            console.log(response.data);
            localStorage.setItem("access_type",response.data.access_type);
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    }; 

    useEffect(() => {
        if (userDetails) {
            setFile(({
                ...file,
                profilePic: userDetails.profilePic,
                Email: userDetails.email,
                AdminName: userDetails.first_name
            }));
        }
    }, [userDetails]);
    
    const fetchDetails_data = async () => {
        try {
            const token = localStorage.getItem('token');
            console.log("trying to get user details");
            console.log(token);
            const response = await api.get(`${corporateUrl}/api/user-details/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const userDetails = response.data;
            setDetails(userDetails);
            localStorage.setItem("access_type", userDetails.access_type);
            return userDetails;
        } catch (error) {
            console.error('Error fetching details:', error);
            return null;
        }
    };
    

    useEffect(() => {
        setTimeout(() => {
            setId(localStorage.getItem('user_id'))
        }, 1000)
        fetchDetails();
    }, [id]); 

    console.log("details log add file: ",userDetails);
    const [file, setFile] = useState({
        filename: '',
        link: '',
        description: '',
        roles: [],
        file: null,
        image: null,
        employeeId: localStorage.getItem('user_id'),
        profilePic: userDetails?.profilePic,
        Email: userDetails?.email,
        AdminName: userDetails?.first_name
    });
    const [selectedFileName, setSelectedFileName] = useState('No file selected');
    const [selectedImage, setSelectedImage] = useState('No image selected');
    const token = localStorage.getItem('token');
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);

    const handleNameInput = (e) => {
        setFile({
            ...file,
            filename: e.target.value
        });
    };

    const handleLinkInput = (e) => {
        setFile({
            ...file,
            link: e.target.value
        });
    };

    const handleDescriptionInput = (e) => {
        setFile({
            ...file,
            description: e.target.value
        });
    };

    const handleRoleChange = (roles) => {
        setFile({ ...file, roles });
    };

    const handleChooseFile = () => {
        fileInputRef.current.click();
    };

    const handleChooseImage = () => {
        imageInputRef.current.click();
    };

    const handleFileInput = (e) => {
        const selectedFile = e.target.files[0];
        setFile({
            ...file,
            file: selectedFile
        });
        setSelectedFileName(selectedFile.name);
    };

    //convertign image to Base64 encoding
    const handleImageInput = (e) => {
        const selectedImageFile = e.target.files[0];
        setSelectedImage(selectedImageFile.name);
    
        const reader = new FileReader();
    
        const imageLoadedPromise = new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    
        reader.readAsDataURL(selectedImageFile);
    
        imageLoadedPromise.then((base64Image) => {
            setFile({
                ...file,
                image: base64Image
            });
        });
    };
    const navigate = useNavigate();
    const HandleSubmit = async (e) => {
        e.preventDefault();
        try {

            const userDetails1 = await fetchDetails_data(); 
            const admin_pic=userDetails.profile_pic;
            // console.log("picture of admin : ",admin_pic);
            // console.log("right before form : ",userDetails1);
            if (userDetails1) {  // Ensure userDetails is not null
                setFile({
                    ...file,
                    profilePic: admin_pic,                    
                    Email: userDetails1.email,
                    AdminName: userDetails1.first_name
                });
            }
            // console.log("right after form",file);

            const formData = new FormData();

            const rolesToSubmit = file.roles.length === availableRoles.length ? 'all' : file.roles;

            if (file.image) {
                formData.append('image', file.image);
            }
    
            if (file.file) {
                formData.append('file', file.file);
            }
    
            formData.append('filename', file.filename);
            formData.append('link', file.link);
            formData.append('description', file.description);
            formData.append('roles', JSON.stringify(rolesToSubmit)); // Append roles as JSON string
            formData.append('employeeId',file.employeeId);
            formData.append('profilePic',admin_pic);
            formData.append('Email',file.Email);
            formData.append('AdminName',file.AdminName);
            console.log(formData.profilePic);

            if(file.filename!='' && file.link!='' && file.description!='' && file.roles!='' && file.image!=null){
            const response = await api.post(`${BaseUrl}/addData`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                }
            });
            if (response.status === 201) {
                alert('Data added successfully!');
                navigate(`/home/${file.employeeId}`);
            } else {
                alert('Failed to add data!');
            }
        }else{
            const allFieldsNotFilled = await new Promise((resolve) => {
                const resolveFunction = (value) => {
                    resolve(value);
                };
                setShowPopup({ visible: true, resolveFunction });
            });
        }

        } catch (error) {
            console.error('Error:', error);
            alert('Failed to add data!');
        }
    };

    return (
        // <div className='add_details_main'>
        //     <div className='add_details_admin_inner'>
        //         <div>
        //             <label className='add_details_admin_main_label'>Add File</label>
        //         </div>
        //         <div className=''>
        //             <span className='all-fields'>* All Fields are required</span>
        //         </div>
        //         <div>
        //             <label className='add_details_admin_name_label'>Enter File Name :
        //             <input className='add_details_admin_name_input' onChange={handleNameInput} placeholder='file name'/>
        //             </label>
        //             <label className='add_details_admin_link_label'>Enter Link:
        //             <input className='add_details_admin_link_input' onChange={handleLinkInput} placeholder='link'/>
        //             </label>
        //             <label className='add_details_admin_description_label'>Enter description:</label>
        //             <textarea className='add_details_admin_description_input' onChange={handleDescriptionInput} placeholder='description'></textarea>
        //             <label className='add_details_admin_roles_label'>Select Roles:</label>
        //             <RolesSelector selectedRoles={file.roles} onRoleChange={handleRoleChange} />
        //             {/* <label className='add_details_admin_file_label'>Upload File:</label> */}
        //             <div className='upload_file_section'>
        //                 <button className='choose_files_button' onClick={handleChooseFile}>Choose File</button>
        //                 <div className="selected_file">{selectedFileName}</div>
        //                 <input ref={fileInputRef} type="file" className='add_details_admin_file_input' onChange={handleFileInput} />
        //             </div>
        //             <label className='add_details_admin_image_label'>Upload Image:</label>
        //             <div className='upload_image_section'>
        //                 <button className='choose_image_button' onClick={handleChooseImage}>Choose Image</button>
        //                 <div className="selected_image">
        //                     {file.image ? (
        //                         <img src={file.image} alt="Selected Image" className="preview-image" />
        //                     ) : (
        //                         <span>No image selected</span>
        //                     )}
        //                 </div>
        //                 <input ref={imageInputRef} type="file" className='add_details_admin_image_input' onChange={handleImageInput} accept="image/*" />
        //             </div>
        //             <div className='submit_admin_file_details' onClick={HandleSubmit}>Save</div>
        //         </div>
        //     </div>

        //     {showPopup.visible && (
        //                         <Popup
        //                             message="All the fields need to be filled to submit !"
        //                             setShowPopup={() => setShowPopup({ visible: false })}
        //                             resolveFunction={showPopup.resolveFunction}
        //                         />
        //                     )}
        // </div>

        <div className="add_details_main">
            <div className='content-sec'>
                <div className='software-form-section'>
                    <div className="softwarehead-head" >   
                        <h3>New Software</h3>
                    </div>
                    <div className="software-form" >
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="fileName">Software Name:<span className="bg-red">*</span></label>
                                <input type="text" id="fileName" name="fileName" onChange={handleNameInput} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="link" className='mr-2'>Link: <span className="bg-red">*</span></label>
                                <input type="url" id="link" name="link" onChange={handleLinkInput} />
                            </div>

                        </div>    
                        <div className="form-row">

                            <div className="form-group">
                                <label htmlFor="description">Description: <span className="bg-red">*</span></label>
                                <textarea type="text" id="description" name="description" onChange={handleDescriptionInput} />
                            </div>
                            
                        </div>    
                        <div className="form-row">

                            <div className="form-group">
                                <label htmlFor="roles">Roles: <span className="bg-red">*</span></label>
                                <RolesSelector selectedRoles={file.roles} onRoleChange={handleRoleChange} />
                            </div>

                        </div>    

                        <div className='upload_file_section'>
                            {/* <button className='choose_files_button' onClick={handleChooseFile}>Choose File</button>
                            <div className="selected_file">{selectedFileName}</div>
                                <input ref={fileInputRef} type="file" className='add_details_admin_file_input' onChange={handleFileInput} /> */}
                            </div>
                            <label className='add_details_admin_image_label'>Upload Image:</label>
                            <div className='upload_image_section'>
                            <button className='choose_image_button' onClick={handleChooseImage}>Choose Image</button>
                            <div className="selected_image">
                                {file.image ? (
                                    <img src={file.image} alt="Selected Image" className="preview-image" />
                                ) : (
                                    <span>No image selected</span>
                                )}
                            </div>
                            <input ref={imageInputRef} type="file" className='add_details_admin_image_input' onChange={handleImageInput} accept="image/*" />
                        </div>

                        {/* <div className='submit_admin_file_details' onClick={HandleSubmit}>Save</div> */}
                    </div>
                    <div className='software-form-button'>
                        <button className='button software-submit-btn' type="submit" onClick={HandleSubmit}>Create</button>
                    </div>
                    {showPopup.visible && (
                        <Popup
                            message="All the fields need to be filled to submit !"
                            setShowPopup={() => setShowPopup({ visible: false })}
                            resolveFunction={showPopup.resolveFunction}
                        />
                    )}
                </div>
            </div>
        </div>
        
    );
}

export default AddDetails;   